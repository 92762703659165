<template>
    <div>
        <!-- Popups -->
        <div>
            <v-row justify="center">
                <v-dialog v-model="auth_error_enable" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                            {{$t("Warning_Message.Unauthorized")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="closeDialogUnauthorized" style="background-color: #1467BF;">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="error_enable" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                            {{$t("Warning_Message.Something_Went_Wrong")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="ageLimitModal" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                        {{$t("Warning_Message.Invalid_Account_YouShould_Be_Above18To_Have_An_Account")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAgeLimitModal" style="background-color: #1467BF;">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="editProfileSuccessModal" max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal; margin-top:20px;">
                            {{$t("Warning_Message.Profile_Updated_Successfully")}}
                        </v-card-title>
                        <v-card-text></v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn align="center" color="primary" depressed @click="closeEditProfileSuccessModal" style="background-color: #1467BF;">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- Popups -->

        <div class="pt-2 py-4 d-flex flex-column">
            <div style="background-color: #F4F6FA;">
                <div class="py-4 d-flex flex-column">
                    <!-- Icon & Name -->
                    <div class="px-4 pb-2 d-flex justify-start">
                        <div class="d-flex justify-center align-center">
                            <img v-if="customer_data.gender.en_text == 'Male'" src="https://s3iconimages.mymedicine.com.mm/male.svg" />
                            <img v-if="customer_data.gender.en_text == 'Female'" src="https://s3iconimages.mymedicine.com.mm/female.svg" />
                        </div>

                        <div class="pl-4 d-flex flex-column">
                            <div align="left"><span class="nameClass"> {{ $store.state.name }} </span></div>
                            <div align="left"><span class="numberClass">{{ customer_data.mobile_number }}</span></div>
                        </div>
                    </div>

                    <!-- NRC, DOB, Gender -->
                    <div class="px-4 pt-2 d-flex justify-space-between align-center">
                        <div class="d-flex flex-column">
                            <div><span class="nameClass">{{ $t("Customer.EditProfile.NRC") }}</span></div>
                            <div><span class="numberClass">{{ customer_data.NRC }}</span></div>
                        </div>

                        <div class="d-flex flex-column">
                            <div><span class="nameClass">{{ $t("Customer.EditProfile.Date_Of_Birth") }}</span></div>
                            <div><span class="numberClass">{{ customer_data.dob }}</span></div>
                        </div>

                        <div class="d-flex flex-column">
                            <div><span class="nameClass">{{ $t("Customer.EditProfile.Gender") }}</span></div>
                            <div v-if="$store.state.locale == 'en'"><span class="numberClass">{{ customer_data.gender.en_text }}</span></div>
                            <div v-if="$store.state.locale == 'mm'"><span class="numberClass">{{ customer_data.gender.mm_text }}</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pa-4">
                <!-- Email -->
                <div>
                    <p class="heading" align="left">{{ $t("Customer.EditProfile.Email") }}</p>

                    <v-text-field class="mr-2 rounded-lg" style="width:100%; border-color: #E0E0E0;" v-model="editEmail" solo flat outlined
                        :placeholder="translation_enter_email"
                        background-color="#F4F6FA"
                    >
                    </v-text-field>
                </div>
                
                <!-- Height -->
                <div>
                    <p class="heading" align="left">{{ $t("Customer.EditProfile.Height") }}</p>

                    <div class="d-flex">
                        <v-text-field v-model="heightInFeet" class="pr-2 rounded-lg" style="width: 5%; border-color: #E0E0E0;" type="number" solo flat outlined
                            :placeholder="translation_feet"
                            :rules="$store.state.locale == 'en' ? heightRules : heightRulesMM"
                            background-color="#F4F6FA"
                            :suffix="translation_Ft"
                        />

                        <v-text-field v-model="heightInInch" class="pl-2 rounded-lg" style="width: 5%; border-color: #E0E0E0;" type="number" solo flat outlined
                            :placeholder="translation_inches"
                            :rules="$store.state.locale == 'en' ? heightRulesIn : heightRulesInMM"
                            background-color="#F4F6FA"
                            :suffix="translation_In"
                        />
                    </div>
                </div>
                
                <!-- Weight -->
                <div>
                    <p class="heading flexbox" align="left">{{ $t("Customer.EditProfile.Weight") }}</p>
                    
                    <v-text-field v-model="editWeight" class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
                        :placeholder="translation_in_lbs"
                        type="number"
                        background-color="#F4F6FA"
                        :suffix="translation_Lb"
                    />
                </div>

                <!-- Smoker -->
                <div>
                    <p class="heading" align="left">{{ $t("Customer.EditProfile.Smoker") }}</p>
                    <v-select v-if="$i18n.locale=='en'" class="rounded-lg flex-item" style="border-color: #E0E0E0;" v-model="editSmoking" :items="smokerList" solo flat outlined
                        type="number"
                        background-color="#F4F6FA"
                        item-text = "en_text"
                        return-object
                    />
                    <v-select v-if="$i18n.locale=='mm'" class="rounded-lg flex-item" style="border-color: #E0E0E0;" v-model="editSmoking" :items="smokerList" item-text="mm_text" solo flat outlined
                        type="number"
                        background-color="#F4F6FA"
                        return-object
                    />
                </div>

                <!-- Alcoholic -->
                <div>
                    <p class="heading" align="left">{{ $t("Customer.EditProfile.Alcoholic") }}</p>

                    <v-select v-if="$i18n.locale=='en'" class="rounded-lg flexbox" style="border-color: #E0E0E0;" v-model="editAlcoholic" :items="alcoholicList" solo flat outlined
                        item-text = "en_text"
                        type="number"
                        background-color="#F4F6FA"
                        return-object
                    />
                    <v-select v-if="$i18n.locale=='mm'" class="rounded-lg flexbox" style="border-color: #E0E0E0;" item-text="mm_text" v-model="editAlcoholic" :items="alcoholicList" solo flat outlined
                        type="number"
                        background-color="#F4F6FA"
                        return-object
                    />
                </div>

                <!-- Save Button -->
                <div style="margin-top: 30px;">
                    <v-btn color="primary" style="width: 100%; padding: 24px 0; margin-top: -3vh; background-color: #1467BF;" @click="updateCustomerInfo" :disabled="isSaveButtonClicked">
                        {{ $t("Customer.EditProfile.Save") }}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'editProfileAppUserComponent',
    props: {
        customer_data: {
            type: Object,
            requied: true
        }
    },
    data() {
        return {
            editProfileSuccessModal: false,
            auth_error_enable: false,
            error_enable: false,
            ageLimitModal: false,
            isSaveButtonClicked: false,
            translation_enter_name: '',
            translation_dob: '',
            translation_enter_email: '',
            translation_enter_number: '',
            translation_enter_nrc: '',
            translation_in_lbs: '',
            translation_feet: '',
            translation_inches: '',
            translation_Ft: '',
            translation_In: '',
            translation_Lb: '',
            editEmail: '',
            heightInFeet: '',
            heightInInch: '',
            editWeight: '',
            editSmoking: '',
            editAlcoholic: '',
            genderList :[{ "key": "male", "mm_text": "ကျား", "en_text": "Male" },{ "key": "female", "mm_text": "မ", "en_text": "Female" }, { "key": "other", "mm_text": "အခြား", "en_text": "Other" }],
            alcoholicList : [{ "key": "non_drinker", "mm_text": "အရက်လုံးဝမသောက်ပါ", "en_text": "Non drinker" }, { "key": "social_drinker", "mm_text": "လူမှုရေးအရသောက်ပါသည်", "en_text": "Social drinker" }, { "key": "moderate_drinker", "mm_text": "အလွန်အကြူးမသောက်ပါ", "en_text": "Moderate drinker" }, { "key": "alcoholic", "mm_text": "အရက်စွဲနေပါသည်", "en_text": "Alcoholic" }],
			smokerList :  [{ "key": "yes", "mm_text": "သောက်သည်", "en_text": "Yes" }, { "key": "no", "mm_text": "မသောက်ပါ", "en_text": "No" }],
            heightRules: [
			    (v) => (v && v <= 10) || "Height cannot be more than 10feet",
			],
			heightRulesMM: [
			    (v) => (v && v <= 10) || "အရပ်သည် ၁၀ ပေ အောက်ဖြစ်သင့်ပါသည်။",
			],
            dobRules : [v => !!v || 'Date of Birth is required'],
            dobRulesMM : [v => !!v || 'မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            heightRulesIn : [v => v <= 12 || 'Inches can not be greater than 12'],
            heightRulesInMM : [v => v <= 12 || 'လက်မသည် ၁၂ လက်မအောက်ဖြစ်ရပါမည်။'],
        }
    },
    mounted() {
        this.editEmail = this.customer_data.email;
        this.heightInFeet = this.customer_data.heightInFeet;
        this.heightInInch = this.customer_data.heightInInch;
        this.editWeight = this.customer_data.weight;
        this.editSmoking = this.customer_data.smoker;
        this.editAlcoholic = this.customer_data.alcoholic;

        if (this.$i18n.locale == "en") {
            this.translation_enter_name = "Enter Name";
            this.translation_dob = "Enter Date Of Birth";
            this.translation_enter_email = "Enter Email";
            this.translation_enter_number = "Enter Phone Number";
            this.translation_enter_nrc = "Enter NRC";
            this.translation_in_lbs = "in lbs";
            this.translation_feet = "feet";
            this.translation_inches = "inches";
            this.translation_Ft = "ft";
            this.translation_In = "in";
            this.translation_Lb = "lb";
        } else {
            this.translation_enter_name = "အမည်ထည့်မည်";
            this.translation_dob = "မွေးနေ့";
            this.translation_enter_email = "အီးလ်မေး";
            this.translation_enter_number = "ဖုန်းနံပါတ် ဖြည့်ပါ";
            this.translation_enter_nrc = "မှတ်ပုံတင်နံပါတ် ဖြည့်ပါ";
            this.translation_in_lbs = "ပေါင်";
            this.translation_feet = "ပေ";
            this.translation_inches = "လက်မ";
            this.translation_Ft = "ပေ";
            this.translation_In = "လက်မ";
            this.translation_Lb = "ပေါင်";
        }
    },
    methods: {
        closeDialogUnauthorized() {
            this.auth_error_enable = false;
            this.$router.push({
                name: "Login",
            });
        },
        closeDialogError() {
            this.error_enable = false;
            this.$router.push({
				name: "UserProfile",
			});
        },
        closeAgeLimitModal() {
            this.ageLimitModal = false;
     	},
        closeEditProfileSuccessModal(){
			this.editProfileSuccessModal = false;
			this.$router.push({
				name: "UserProfile",
			});
		},
        updateCustomerInfo() {
            this.isSaveButtonClicked = true;

            this.editHeight = this.heightInFeet +"\'"+ this.heightInInch + "\"";
			let modifyCustomerData = {
				token : this.customer_data.token,
				typeOfUser : "CUSTOMER",
				height : this.editHeight ? this.editHeight : '0\'0"',
				weight : this.editWeight ? this.editWeight : 0,
				email : this.editEmail ? this.editEmail : '',
				smoker : this.editSmoking ? this.editSmoking.en_text : "",
				alcoholic : this.editAlcoholic ? this.editAlcoholic.en_text : "",
			}
			axios.post(process.env.VUE_APP_BACKEND_URL + "/modifyCustomer", modifyCustomerData)
			.then(() => {
				this.isSaveButtonClicked = false;
				this.editProfileSuccessModal = true;
			})
			.catch((modifyCustomerError) => {
                console.log('Error modifying customer: ', modifyCustomerError);
                if (modifyCustomerError.response && modifyCustomerError.response.status == 401) {
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
			});
        }
    }
}
</script>

<style>
.disabledClass .v-text-field__slot input {
	color: white !important;
}
.disabledClass .v-select__selection--disabled {
	color: white !important;
}
.v-select__selection {
  line-height: 2.2 !important;
}
.v-select__slot.v-select__selection.v-select__selection--comma{
  line-height: 2.2 !important;
}
.v-list-item .v-list-item__title{
	line-height: 2.2 !important;
}
</style>

<style scoped>
.nameClass {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #333333;
}

.numberClass {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #333333;
}

.heading {
    font-size: 16px;
    font-weight: bold;
}
</style>